import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import * as classes from './GalleryPreview.module.scss';
import classNames from 'classnames';

export interface GalleryPreviewProps {
    photo?: ImageDataLike;
    onClose: () => void;
}

const GalleryPreview: React.FC<GalleryPreviewProps> = ({
    photo,
    onClose
}) => {
    const containerStyles = classNames({
        [classes['container']]: true,
        [classes['open']]: !!photo
    });
    
    return (
        <div className={containerStyles} onClick={onClose}>
            <div className={classes['preview']}>
                {
                    photo && (
                        <GatsbyImage 
                            image={getImage(photo)}
                            alt="Project Photo"
                            objectFit="cover"
                            objectPosition="center"
                            className={classes['preview__photo']}
                        />
                    )
                }
            </div>
        </div>
    );
}

export { GalleryPreview }