import React from 'react';
import * as classes from './Hero.module.scss';
import { faInstagram, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface HeroProps {
    title: string;
    subtitle: string;
    icon: React.ReactNode;
}

const Hero: React.FC<HeroProps> = ({
    title,
    subtitle,
    icon
}) => {
    return (
        <section className={classes['hero']}>
           
            <div className={classes['icon']}>
                <div className={classes['icon__content']}>
                    <div className={classes['icon__svg']}>
                        { icon }
                    </div>
                </div>
                <div className={classes['icon__background']} />
            </div>

            <header className={classes['header']}>

                <h2 className={classes['header__subtitle']}>
                    { subtitle }
                </h2>

                <h1 className={classes['header__title']}>
                    { title }
                </h1>

                <div className={classes['social']}>
                    <a href={process.env.GATSBY_SOCIAL_LINKEDIN} rel="noreferrer" target="_blank" className={classes['button']}>
                        <div className={classes['button__label']}>
                            <FontAwesomeIcon icon={faInstagram} />
                        </div>
                        <div className={classes['button__hover']} />
                    </a>
                    <a href={process.env.GATSBY_SOCIAL_LINKEDIN} rel="noreferrer" target="_blank" className={classes['button']}>
                        <div className={classes['button__label']}>
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </div>
                        <div className={classes['button__hover']} />
                    </a>
                    <a href={process.env.GATSBY_SOCIAL_GITHUB} rel="noreferrer" target="_blank" className={classes['button']}>
                        <div className={classes['button__label']}>
                            <FontAwesomeIcon icon={faGithub} />
                        </div>
                        <div className={classes['button__hover']} />
                    </a>
                </div>
            </header>

        </section>
    );
}

export { Hero }