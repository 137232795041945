// extracted by mini-css-extract-plugin
export var hero = "Hero-module--hero--2JSoT";
export var icon = "Hero-module--icon--2MoV6";
export var icon__content = "Hero-module--icon__content--282gF";
export var icon__background = "Hero-module--icon__background--36pvn";
export var icon__svg = "Hero-module--icon__svg--1Kpqk";
export var header = "Hero-module--header--E2f22";
export var header__subtitle = "Hero-module--header__subtitle--2uGZm";
export var header__title = "Hero-module--header__title---fQ3r";
export var social = "Hero-module--social--3vJB4";
export var button = "Hero-module--button--2XXdS";
export var button__label = "Hero-module--button__label--14gKG";
export var button__hover = "Hero-module--button__hover--3DHS9";