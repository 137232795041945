import React from 'react';
import * as classes from './About.module.scss';

export interface AboutProps {
    categories: string[];
    type: string;
    duration: string;
    description: string[];
}

const About: React.FC<AboutProps> = ({
    categories,
    type,
    duration,
    description
}) => {
    return (
        <section className={classes['about']}>

            <div className={classes['grid']}>
                <div className={classes['info']}>
                    <div className={classes['info__item']}>
                        <div className={classes['info__label']}>
                            Scope of Work
                        </div>
                        <div className={classes['info__value']}>
                            { categories.join(', ') }
                        </div>
                    </div>
                    <div className={classes['info__item']}>
                        <div className={classes['info__label']}>
                            Category
                        </div>
                        <div className={classes['info__value']}>
                            { type }
                        </div>
                    </div>
                    <div className={classes['info__item']}>
                        <div className={classes['info__label']}>
                            Duration
                        </div>
                        <div className={classes['info__value']}>
                            { duration }
                        </div>
                    </div>
                </div>

                <div>

                    {
                        description.map((paragraph, i) => 
                            <p key={i} className={classes['content__paragraph']}>
                                { paragraph }
                            </p>
                        )
                    }

                </div>
            </div>

        </section>
    );
}

export { About }