import React, { useState } from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import { GalleryPreview } from './GalleryPreview';
import * as classes from './Gallery.module.scss';

export interface GalleryProps {
    data: ImageData[];
}

export interface ImageData {
    name: string;
    node: ImageDataLike;
}

const Gallery: React.FC<GalleryProps> = ({
    data
}) => {
    // State
    const [preview, setPreview] = useState<ImageDataLike>();

    // Handlers
    const handlePreviewOpen = (node: ImageDataLike) => {
        setPreview(node);
    }

    const handlePreviewClose = () => {
        setPreview(undefined);
    }

    // Helpers
    const renderImage = ({ name, node }: ImageData) => {
        const image = getImage(node);
    
        return (
            <GatsbyImage 
                key={name}
                image={image}
                alt="Project Photo"
                objectFit="cover"
                objectPosition="center"
                className={classes['photo']}
                onClick={() => { handlePreviewOpen(node); }}
            />
        );
    }

    return (
        <section className={classes['gallery']}>

            <div className={classes['slideshow']}>
                { data.map((image) => renderImage(image)) }
            </div>

            <GalleryPreview 
                photo={preview}
                onClose={handlePreviewClose}
            />

        </section>
    );
}

export { Gallery }